<template>
    <v-dialog scrollable v-model="show">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" :disabled="ordersCount === 0" icon>
                <v-badge overlap :content="ordersCount" :value="ordersCount" color="red">
                    <v-icon large>mdi-trash-can-outline</v-icon>
                </v-badge>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="d-flex justify-space-between text-h5 grey lighten-2">
                Удаленные заказы
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="primary" text @click="handleClose(false)">Закрыть</v-btn>
                </v-card-actions>
            </v-card-title>
            <template>
                <v-card>
                    <slot></slot>
                </v-card>
            </template>
        </v-card>
    </v-dialog>
</template>

<script>
    import moment from 'moment-timezone'
    moment.locale('ru')
    import Status from '../../../mixins/Status'

    export default {
        mixins: [Status],
        props: {
            visible: Boolean,
            ordersCount: Number
        },
        model: {
            prop: 'visible',
            event: 'close'
        },
        data() {
            return { 
                show: this.visible
            }
        },
        watch: {
            visible: function(newValue) {
                this.show = newValue;
            },
            show: function(newValue) {
                if(!newValue)
                    this.handleClose(newValue);
            }
        },
        methods: {
            handleClose(value) {
                console.log(value);
                this.$emit('close', value);
                this.show = false;
            }
        }
    }
</script>

<style lang="stylus">

</style>