<template>
<v-dialog v-model="show" width="unset">
    <v-card>
        <v-card-title class="headline grey lighten-2">
            {{ header }}
        </v-card-title>
        <v-card-text>
            <div class="d-flex px-4 py-5 justify-md-space-around">
                <v-btn class="ma-2" v-if="currentItemStatus == config.order.status.way_guest || currentItemStatus == config.order.status.way_point || currentItemStatus == config.order.status.passed||currentItemStatus == config.order.status.pending" :color="orderStatusColorHtml(config.order.status.new)" dark @click="$emit('new')"
                    >Новый</v-btn
                >
                <v-btn
                    class="ma-2"
                    v-if="currentItemStatus == config.order.status.way_point || currentItemStatus == config.order.status.way_guest || currentItemStatus == config.order.status.passed || worker_tid"
                    :color="orderStatusColorHtml(config.order.status.complete)"
                    dark
                    @click="$emit('complete')"
                    >Выполнен</v-btn
                >
                <v-btn class="ma-2" v-if="currentItemStatus == config.order.status.forming" color="error" dark @click="$emit('delete')">Удалить</v-btn>
            </div>
            <v-divider></v-divider>
            <span v-html="dialogContent"></span>
        </v-card-text>
    </v-card>
</v-dialog>
</template>

<script>
import config from '../../../config'
import moment from 'moment-timezone'

moment.locale('ru')

import Status from '../../../mixins/Status'

export default {
    mixins: [Status],
    props: {
        visible: Boolean,
        header: String,
        currentItemStatus: Number,
        worker_tid: Number,
        dialogContent: String,
    },
    model: {
        prop: 'visible',
        event: 'close'
    },
    data() {
        return { 
            content: this.value, 
            config,
            show: this.visible
        }
    },
    watch: {
        visible: function(newValue) {
            this.show = newValue;
        },
        show: function(newValue) {
            if(!newValue)
                this.handleClose(newValue);
        }
    },
    methods: {
        handleClose(value) {
            this.$emit('close', value)
        },
    }
}
</script>

<style lang="stylus">

</style>