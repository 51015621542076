<template>
    <v-container class="pa-0">
        <v-snackbar right bottom :value="text" :timeout="6000" v-model="visible" color="info">
            <v-progress-circular color="deep-orange lighten-2" :value="Math.floor(100 * (currentTime / snackbarTime))" class="mx-2">
                <strong>{{ snackbarLoad }}</strong>
            </v-progress-circular>
            <span class="mx-2 bold">
                <b>{{ text }}</b>
            </span>
            <v-btn color="deep-orange " @click="onCancel"> <span color="black">Восстановить</span> </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import moment from 'moment-timezone'
    moment.locale('ru')
    import Status from '../../mixins/Status'

    export default {
        mixins: [Status],
        props: {
            text: String,
            visible: Boolean,
        },
        model: {
            prop: 'visible',
            event: 'close'
        },
        data() {
            return {
                snackbarTime: 5000,
                currentTime: 0,
                timerId: -1
            }
        },
        computed: {
            snackbarLoad() {
                return Math.floor((this.snackbarTime - this.currentTime) / 1000)
            }
        },
        watch: {
            visible: function(newValue, oldValue) {
                console.log(newValue, oldValue);
                if(newValue) this.startTimer();
            }
        },
        methods: {
            onCancel() {
                clearInterval(this.timerId);
                this.$emit('close', false);
                this.$emit('cancel');
            },
            startTimer() {
                console.log("Started timer")
                this.timerId = setInterval( function(){
                    if(this.currentTime >= this.snackbarTime) {
                        clearInterval(this.timerId);
                        this.currentTime = 0;
                        this.$emit('execute');
                        this.$emit('close', false);
                    } else {
                        console.log('tick', this.currentTime);
                        this.currentTime += 1000;
                    }   
                }.bind(this), 1000);
            }
        }

    }
</script>

<style lang="stylus">

</style>