<template>
    <v-dialog v-model="show" width="720">
        <v-card>
            <v-card-title class="headline grey lighten-2">
                {{ header }}
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field :disabled="userIsService" v-model="itemCopy.guest" label="Гость" hide-details></v-text-field>
                            </v-col>
                            <v-col cols="8">
                                <v-text-field :disabled="userIsService" v-model="itemCopy.finish_addr" label="Адрес заказа" @input="handleAddressChange" hide-details></v-text-field>
                            </v-col>
                            
                            <v-col cols="12">
                                <v-text-field :disabled="userIsService" v-model="itemCopy.pay_cost" label="Цена заказа" hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field :disabled="userIsService" v-model="itemCopy.descr" label="Описание" hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!userIsService && itemCopy.service_tid">
                                <span
                                    >Менеджер: <b>{{ itemCopy.service_tid }}</b></span
                                >
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="!userIsService" :disabled="currentItemStatus === config.order.status.forming" class="mx-2" color="success" dark @click="handleSave()">Сохранить</v-btn>
                <v-btn v-if="!userIsService" :disabled="disBtn" class="mx-2" color="error" text @click="$emit('delete')">Удалить</v-btn>
                <v-btn class="mx-2" color="primary" text @click="handleClose(false)">Закрыть</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import config from '../../../config';
    import moment from 'moment-timezone'
    moment.locale('ru')
    import Status from '../../../mixins/Status'

    export default {
        mixins: [Status],
        props: {
            visible: Boolean,
            header: String,
            currentItemStatus: Number,
            selectedItem: Object,
            userIsService: Boolean,
            disBtn: Boolean
        },
        model: {
            prop: 'visible',
            event: 'close'
        },
        data: function() {
            return { 
                config,
                show: this.visible,
                itemCopy: JSON.parse(JSON.stringify(this.selectedItem))
            }
        },
        watch: {
            visible: function(newValue) {
                this.show = newValue;
            },
            show: function(newValue) {
                if(!newValue)
                    this.handleClose(newValue);
            },
            selectedItem: function(newItem) {
                this.itemCopy = JSON.parse(JSON.stringify(newItem));
            }
        },
        methods: {
            handleClose(value) {
                this.$emit('close', value);
            },
            handleSave() {
                this.$emit('save', this.itemCopy);
            },
            handleAddressChange() {
                this.$emit('address-change', this.itemCopy);
            }
        }
    }
</script>

<style lang="stylus">

</style>